.Footer2 {
  background: rgba(58, 140, 150, 0.25);
  padding: 60px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 auto;
}

.Footer2 a,
.Footer2 p {
  font-family: 'Work Sans';
  font-size: 15px;
  color: #000;
  text-align: left;
  font-weight: 400;
}

.ar .Footer2 a,
.ar .Footer2 p {
  text-align: right;
}

.Footer2 #follow a:visited,
.Footer2 #follow a {
  color: #000;
  font-weight: lighter;
  margin-top: 40px;
  display: inline-block;
}

.Footer2 .footer-element {
  margin: 0 20px;
  margin-bottom: 20px;
  min-width: 270px;
}

.Footer2 .footer-element p {
  margin: 0;
  margin-bottom: 20px;
}
