@media only screen and (max-width: 1023px) {
  body {
    min-width: 0;
  }

  .TopBar {
    background: #fcfaf7;
    min-width: 0;
    max-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .Header {
    position: static;
    width: 1100px;
    margin: 200px auto 0 auto;
    min-height: 1000px;
  }

  .Timeline {
    margin-top: 80px;
  }
}
