.Stories {
  width: 1024px;
  margin: 0 auto;
  margin-top: 80px;
}

.Stories h1 {
  font-family: Rakkas;
  font-size: 48px;
  text-align: center;
  font-weight: lighter;
  text-transform: uppercase;
  margin-top: -27px;
  margin-bottom: 15px;
}

.Stories h2 {
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 300;
  width: 800px;
  text-align: center;
  margin: 0 auto;
}

.Stories .story-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.Stories .story-text {
  flex: 1;
  margin-left: 75px;
  margin-right: 0;
  font-family: 'Work Sans';
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 300;
  height: 800px;
}

.ar .Stories .story-text {
  margin-right: 75px;
  margin-left: 0;
}

.Stories .story-text div {
  overflow: auto;
  height: 600px;
}

.Stories .story-circles {
  width: 600px;
  max-width: 540px;
  height: 600px;
  float: right;
  position: relative;
  margin-bottom: 30px;
}

.Stories .circle-container {
  width: 150px;
  height: 150px;
  position: absolute;
  cursor: pointer;
}

.Stories #circle-0 {
  top: 75px;
  left: 0;
}

.Stories #circle-1 {
  top: 0;
  left: 230px;
}

.Stories #circle-2 {
  top: 195px;
  right: 0;
}

.Stories #circle-3 {
  left: 225px;
  bottom: 0;
}

.Stories #circle-4 {
  bottom: 70px;
  left: 0;
}

.Stories .circle {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
  background-color: #d9324e;
  opacity: 0.25;
  position: absolute;
  margin-left: -45px;
  margin-top: -45px;
  left: 50%;
  top: 50%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.Stories .circle img {
  border-radius: 50%;
  opacity: 0.7;
}

.Stories .circle:hover,
.Stories .circle.circle-active {
  opacity: 1;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.Stories .circle:hover img,
.Stories .circle.circle-active img {
  opacity: 1;
}

.Stories #credits {
  clear: both;
  width: 500px;
  font-family: 'Work Sans';
  font-size: 12px;
  text-decoration: underline;
}

.Stories #credits a,
.Stories #credits a:visited {
  color: #000;
}

.Stories .map {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -100px;
  width: 200px;
  height: 200px;
  text-align: center;
  background: #fcfaf7;
  overflow: hidden;
}

.Stories .circle-container:hover + .map {
  display: block !important;
  z-index: 1 !important;
}

.Stories .place {
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}
