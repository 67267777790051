.TopBar {
  padding: 13px 40px;
  border-bottom: 1px solid #d9324e;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  position: relative;
  z-index: 999;
  min-width: 1024px;
  box-sizing: border-box;
}

.TopBar img {
  max-width: 100px;
  height: 50px;
  cursor: pointer;
}

.TopBar ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.TopBar li {
  font-family: 'Rakkas';
  font-size: 18px;
  list-style: none;
  cursor: pointer;
}

.TopBar li + li {
  margin-left: 40px;
}

.ar .TopBar li + li {
  margin-right: 40px;
  margin-left: 0;
}

.TopBar li span.link {
  border-bottom: 6px solid transparent;
  margin: 0 5px;
  display: inline-block;
}

.TopBar .link {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-out;
  height: 20px;
}

.TopBar .link:hover .slider {
  width: 100%;
}

.TopBar a, .TopBar a:visited {
  color: #000;
  text-decoration: none;
}

.TopBar .current .label {
  border-bottom: 7px solid black;
}

.TopBar .slider {
  position: absolute;
  display: block;
  left: 0;
  top: 27px;
  margin: 0 auto;
  height: 7px;
  background-color: #000;
  width: 0%;
  transition: width 1s ease;
}
