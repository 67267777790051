html, body {
  background: #fcfaf7;
}

.Video, .Video video {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
}

.Video {
  background: #fcfaf7;
  top: 77px;
  height: -webkit-calc(100% - 77px);
  height:    -moz-calc(100% - 77px);
  height:         calc(100% - 77px);
}

.Video #skip {
  font-family: Rakkas;
  font-size: 28px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase; 
  background: #fcfaf7;
  color: #000;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  z-index: 2;
  right: 20px;
  bottom: 80px;
}
