.Header {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50%;
  top: 200px;
  width: 100%;
}

.Header * {
  margin: 0 auto;
}

.Header #dot {
  width: 8px;
  height: 8px;
  background: #ff0030;
  border-radius: 50%;
  display: none;
}

.Header #line {
  height: 130px;
  width: 2px;
  background: #fcfaf7;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Header #line-container {
  position: relative;
  height: 130px;
  background: #000;
  width: 2px;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Header h1 {
  font-family: 'Roboto';
  font-size: 88px;
  text-align: center;
  max-width: 1000px;
  min-height: 210px;
}

.Header #title-placeholder {
  height: 210px;
}

.Header #particles {
  margin-top: -320px;
}
