.Timeline {
  margin: 0 auto;
  margin-top: 965px;
  width: 1024px;
}

.Timeline h1 {
  font-family: Rakkas;
  font-size: 48px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.Timeline h2 {
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 300;
  width: 800px;
  text-align: center;
  margin: 0 auto;
}

.Timeline .timeline-content {
  display: flex !important;
  box-sizing: border-box;
  outline: 0;
}

.ar .Timeline #timeline-content {
  flex-direction: row-reverse;
}

.Timeline .year {
  flex: 1;
}

.Timeline .year p {
  font-family: 'Work Sans';
  font-weight: lighter;
  font-size: 16px;
}

.Timeline .year p a:visited,
.Timeline .year p a {
  color: #000;
}

.Timeline .dots {
  font-family: 'Varela Round';
  margin-left: 30px;
  flex: 3;
  line-height: 8px;
  font-size: 16px;
  position: relative;
  font-weight: bolder;
}

.ar .Timeline .dots {
  margin-left: 0;
  margin-right: 30px;
}

.Timeline .colorful-dots {
  color: #eb5a73;
  clear: both;
}

.Timeline .grey-dots {
  color: #e1e1e1;
}

.Timeline h3 {
  margin: 0;
  font-family: 'Work Sans';
  color: #d9324e;
  font-weight: 700;
  font-size: 30px;
}

.Timeline .dots p {
  color: #d9324e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
  margin-top: 0;
}

.Timeline .dots p + p b {
  font-family: 'Work Sans';
  font-weight: 300;
  font-size: 50px;
  color: #d9324e;
  margin: 5px 0;
}

.Timeline .dots p + p span {
  font-family: 'Work Sans';
  font-weight: 300;
  font-size: 15px;
  color: #000;
  float: right;
}

.ar .Timeline .dots p + p span {
  float: left;
}

.Timeline .dots.insuf-data p,
.Timeline .dots.insuf-data .colorful-dots,
.Timeline .dots.insuf-data .grey-dots,
.Timeline .dots.insuf-data p + p b,
.Timeline .dots.insuf-data p + p span {
  color: #e1e1e1;
}

.Timeline .dots.insuf-data p + p b {
  visibility: hidden;
}

.Timeline .dots .insuf-data-sentence {
  display: none;
}

.Timeline .dots.insuf-data .insuf-data-sentence {
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Work Sans';
  font-size: 32px;
  z-index: 1;
  color: #333333;
  font-weight: bold;
  top: 140px;
}

.Timeline #credits {
  margin: 140px 0 10px 0;
  font-family: 'Work Sans';
  font-size: 12px;
  font-weight: 400;
}

.Timeline #credits a,
.Timeline #credits a:visited {
  color: #000;
}

.Timeline .slick-slider {
  margin: 30px 0;
}

.Timeline .slider-out {
  margin-bottom: 0;
  margin-top: 40px;
}

.Timeline .slick-slider {
  margin-top: 0;
}

.Timeline .slick-dots {
  bottom: -80px;
  text-align: right;
}

.ar .Timeline .slick-dots {
  text-align: left;
}

.Timeline .slick-dots li {
  margin: 0;
  display: inline-block;
  width: 43px;
  height: 21px;
}
  
.Timeline .slick-dots li span {
  display: inline-block;
  width: 43px;
  height: 21px;
  background: transparent url(timeline-first.png) center center no-repeat;
  margin: 0;
  font-family: 'Work Sans';
  font-size: 15px;
  color: #808080;
}

.Timeline .slick-dots li + li span {
  background-image: url(timeline.png);
}

.Timeline .slick-dots li.slick-active span {
  background-image: url(timeline-current.png);
  color: #000;
}

.Timeline .slick-dots li:last-child.slick-active span {
  background-image: url(timeline-last-current.png);
}

.Timeline .slick-dots li:last-child span {
  background-image: url(timeline-last.png);
}

.Timeline .slick-dots b {
  font-weight: 300;
  display: block;
  margin-top: -30px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.Timeline .slick-dots li.slick-active b {
  font-weight: 700;
}

.Timeline .slick-next:before,
.Timeline .slick-prev:before {
  content: '';
}

.Timeline .slick-next,
.Timeline .slick-prev {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-position: center center;
  background-color: transparent;
  background-repeat: no-repeat;
  z-index: 2;
  bottom: -128px;
}

.Timeline .slick-next {
  background-image: url(next.png);
  top: auto;
}

.ar .Timeline .slick-next {
  right: auto;
}

.Timeline .slick-next:hover {
  background-image: url(next-hover.png);
}

.Timeline .slick-prev {
  background-image: url(prev.png);
  top: auto;
  left: auto;
}

.ar .Timeline .slick-prev {
  right: auto;
}

.Timeline .slick-prev:hover {
  background-image: url(prev-hover.png);
}
