.Footer {
  width: 100%;
  padding: 60px 0;
  padding-bottom: 70px;
  margin: 0 auto;
  background: #3a8c96; /* Old browsers */
  background: -moz-linear-gradient(top, #3a8c96 0%, #fcfaf7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #3a8c96 0%,#fcfaf7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, #3a8c96 0%,#fcfaf7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3a8c96', endColorstr='#fcfaf7',GradientType=1 ); /* IE6-9 */
}

.Footer .footer-inner {
  width: 1024px;
  margin: 0 auto;
}

.Footer h1 {
  font-family: 'Work Sans';
  font-size: 48px;
  text-align: center;
  color: #1a1a1a;
  font-weight: 700;
  margin-top: -9px;
}

.Footer h2 {
  font-family: 'Work Sans';
  font-size: 75px;
  color: #d9324e;
  text-align: center;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: -14px;
}

.Footer .paragraph1 {
  font-family: 'Work Sans';
  font-size: 20px;
  text-align: center;
  color: #000;
  font-weight: 300;
  line-height: 1.5em;
}

.Footer .paragraph1 a,
.Footer .paragraph1 a:visited {
  color: #000;
}

.Footer .flow {
  text-align: center;
  padding: 25px 0;
}

.Footer .flow img {
  max-width: 95%;
}

.Footer h3 {
  font-family: 'Work Sans';
  font-size: 48px;
  color: #fcfaf7;
  text-align: center;
  font-weight: 700;
  margin-top: -12px;
  margin-bottom: 0;
}

.Footer .paragraph2 {
  font-family: 'Work Sans';
  font-size: 20px;
  color: #fcfaf7;
  text-align: center;
  font-weight: 300;
  line-height: 1.5em;
}

.Footer .social {
  text-align: center;
}

.Footer .social img {
  margin: 10px;
}

.Footer h4 {
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 700;
  color: #fcfaf7;
  text-align: left;
  margin-top: 69px;
  margin-bottom: 21px;
}

.ar .Footer h4 {
  text-align: right;
}

.Footer .columns {
  width: 940px;
  margin: 0 auto;
}

.Footer .columns p {
  margin: 0;
  padding: 0;
  float: left;
  font-family: 'Work Sans';
  font-size: 15px;
  color: #fcfaf7;
  text-align: left;
  width: 300px;
  line-height: 1.5em;
  font-weight: 300;
}

.ar .Footer .columns p {
  float: right;
  text-align: right;
}

.Footer .columns p.center {
  margin: 0 20px;
}
