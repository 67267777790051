.Risk {
  clear: both;
  text-align: center;
  margin: 0 auto;
  width: 1024px;
}

.Risk h1 {
  font-family: Rakkas;
  font-size: 48px;
  text-align: center;
  font-weight: lighter;
  text-transform: uppercase;
  margin-top: -123px;
  margin-bottom: 15px;
}

.Risk h2 {
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: lighter;
  width: 800px;
  text-align: center;
  margin: 0 auto;
}

.Risk #canvas-container {
  width: 1000px;
  height: 875px;
  margin: 0 auto;
  position: relative;
}

.Risk canvas {
  position: absolute;
  left: 50%;
  top: 50%;
}

.Risk #canvas1 {
  margin-left: -500px;
  margin-top: -500px;
}

.Risk #canvas2 {
  margin-left: -80px;
  margin-top: -80px;
}

.Risk #canvas-container p {
  text-align: left;
  position: absolute;
  top: 20px;
  left: 0;
  right: auto;
  color: #d9324e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-weight: bold;
}

.ar .Risk #canvas-container p {
  text-align: right;
  right: 0;
  left: auto;
}

.Risk #canvas-container p + p {
  left: auto;
  right: 0;
  text-align: right;
  color: #41a09f;
}

.ar .Risk #canvas-container p + p {
  left: 0;
  right: auto;
  text-align: left;
}

.Risk #canvas-container p span {
  font-family: 'Work Sans';
  font-weight: lighter;
  font-size: 60px;
}

.Risk #selected,
.Risk #select {
  position: absolute;
  left: 0;
  right: auto;
  top: 150px;
  z-index: 1;
  text-align: left;
  font-family: 'Work Sans';
  font-size: 16px;
  cursor: pointer;
  width: 300px;
  padding: 10px;
}

.ar .Risk #selected,
.ar .Risk #select {
  right: 0;
  left: auto;
  text-align: right;
}

.Risk #selected {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d9324e;
  background: #fcfaf7;
  border-radius: 60px;
}

.Risk #select {
  background: #fff;
  border: 1px solid #d9324e;
  border-radius: 20px;
}

.Risk .choose {
  display: flex;
  justify-content: space-between;
}

.Risk #selected, .Risk .choose, .Risk .option:hover {
  font-weight: bold;
}

.Risk #canvas-container #credit {
  font-family: 'Work Sans';
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
  font-weight: normal;
  color: #000;
}

.ar .Risk #canvas-container #credit {
  left: auto;
  right: 0;
}

.Risk #credit a,
.Risk #credit a:visited {
  color: #000;
}

.Risk #canvas-container #disclaimer {
  font-family: 'Work Sans';
  font-size: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  top: auto;
  font-weight: 300;
  color: #000;
}

.ar .Risk #canvas-container #disclaimer {
  right: auto;
  left: 0;
}

.Risk #dots {
  position: absolute;
  width: 800px;
  height: 800px;
  left: 50%;
  top: 50%;
  margin-top: -400px;
  margin-left: -400px;
  z-index: 0;
}

.Risk #canvas3 {
  border: 2px solid #ffe700;
  border-radius: 50%;
  left: 0;
  right: auto;
  bottom: auto;
  margin: 0;
  top: 400px;
  z-index: 1;
}

.ar .Risk #canvas3 {
  left: auto;
  right: 0;
}

.Risk #canvas-container #legend {
  background: #fcfaf7;
  font-family: 'Work Sans';
  font-size: 24px;
  color: #1a1a1a;
  left: 0;
  right: auto;
  top: 200px;
  width: 200px;
  text-align: left;
  font-weight: lighter;
}

.ar .Risk #canvas-container #legend {
  left: auto;
  right: 0;
  text-align: right;
}

.Risk .shelter-value {
  display: block;
}

.Risk #vis {
  width: 1024px;
  height: 575px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding-top: 250px;
}

.Risk #vis2 {
  width: 1024px;
  height: 575px;
  position: absolute;
  left: -450px;
  right: auto;
  top: 150px;
}

.ar .Risk #vis2 {
  right: -450px;
  left: auto;
}

.Risk #border {
  position: absolute;
  border-radius: 50%;
  border: 2px solid #fc3;
  display: none !important;
}
