.Map {
  width: 1024px;
  margin: 0 auto;
}

.Map h1 {
  font-family: Rakkas;
  font-size: 48px;
  text-align: center;
  font-weight: lighter;
  text-transform: uppercase;
  margin-top: 51px;
  margin-bottom: 15px;
}

.Map h2 {
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 300;
  width: 800px;
  text-align: center;
  margin: 0 auto;
}

.Map .content {
  display: flex;
  margin-top: 30px;
}

.Map .left {
  flex: 1;
  box-sizing: border-box;
  min-width: 250px;
  max-width: 250px;
}

.Map .center {
  flex: 2;
  min-width: 500px;
  max-width: 500px;
}

.Map #map {
  margin-top: -135px;
}

.Map .right {
  flex: 1;
  display: table;
  min-width: 274px;
  max-width: 274px;
}

.Map .line {
  display: table-row;
  cursor: pointer;
}

.Map .focused .line {
  opacity: 0.5;
}

.Map .focused .line:hover,
.Map .focused .line.current {
  opacity: 1;
}

.Map .cell {
  display: table-cell;
  text-align: right;
  font-family: 'Work Sans';
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  vertical-align: middle;
  padding: 5px;
  min-width: 114px;
  max-width: 114px;
}

.ar .Map .cell {
  text-align: left;
}

.Map .cell + .cell {
  min-width: 160px;
  max-width: 160px;
}

.Map .cell:hover,
.Map .current .cell {
  font-weight: 700 !important;
}

.Map .bar {
  height: 20px;
  position: relative;
  z-index: 0;
}

.Map .displaced-bar, .Map .returned-bar {
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 1px;
}

.ar .Map .displaced-bar, .ar .Map .returned-bar {
  left: auto;
  right: 0;
}

.Map .returned-bar {
  background-color: #41a09f;
  z-index: 1;
  min-width: 2px;
}

.Map .displaced-bar {
  background-color: #d9324e;
  z-index: 2;
}

.Map #credits {
  margin-top: -100px;
  font-family: 'Work Sans';
  font-size: 12px;
}

.Map #credits a,
.Map #credits a:visited {
  color: #000;
}

.Map .label {
  font-family: 'Work Sans';
  font-size: 18px;
  font-weight: 700;
}

.Map .value {
  font-family: 'Work Sans';
  font-size: 54px;
  font-weight: 300;
  letter-spacing: -2px;
}

.Map .left p {
  margin: 0;
  margin-bottom: 54px;
}

.Map .displaced {
  color: #d9324e;
}

.Map .returned {
  color: #41a09f;
}

.Map .center {
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-weight: 400;
}

.Map .center p {
  margin: 2px 0;
  font-weight: bold;
}

.Map .gradient, .Map .gradient-legend {
  height: 16px;
  width: 320px;
  display: inline-block;
}

.Map .gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fed0d8+0,d9324e+100 */
  background: #fed0d8; /* Old browsers */
  background: -moz-linear-gradient(left, #fed0d8 0%, #d9324e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #fed0d8 0%,#d9324e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #fed0d8 0%,#d9324e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fed0d8', endColorstr='#d9324e',GradientType=1 ); /* IE6-9 */
}

.Map .gradient-legend {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
