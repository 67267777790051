.Particles {
  position: relative;
  width: 1024px;
}

.Particles canvas {
  display: block;
  margin: 50px auto;
  position: absolute;
  z-index: 0;
}

.Particles #mask {
  position: absolute;
  z-index: 1;
  padding-top: 550px;
  display: none;
  text-align: center;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  font-size: 26px;
  font-family: 'Work Sans';
  font-weight: 500;
}

.Particles #mask span.number {
  display: block;
  color: #d9324e;
  font-size: 52px;
  font-weight: 700;
}

.Particles #legend {
  position: absolute;
  z-index: 1;
  padding-top: 400px;
  width: 300px;
  height: 75px;
  background: transparent url(line.png) bottom center no-repeat;
  font-family: 'Work Sans';
  font-weight: 300;
  font-size: 15px;
  display: none;
}

.ar .Particles #legend {
  background: transparent url(line-ar.png) bottom center no-repeat;
}

.Particles #credit {
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 12px;
  display: none;
  position: absolute;
  left: 0;
  top: 1000px;
  z-index: 1;
}

.Particles #credit a,
.Particles #credit a:visited {
  color: #000;
}
